import axios from "axios";
import authHeader from './auth-header';
import AuthService from "./auth.service";

const API_URL = "https://backend.whatsmojo.com/api/user/";
// const API_URL = "http://localhost:8080/api/user/";

const qs = require('qs');

const headers = {
  'apikey': process.env.REACT_APP_APIKEY,
  'Content-Type': 'application/x-www-form-urlencoded'
}

class UserService {
  getOnboardingState(shopId) {
    // return axios.get(API_URL + shopId, { headers: authHeader() }).catch(error => { if (error.response.status === 401) { AuthService.logout(); } });
    const postData = qs.stringify({
      'shopId': shopId
    })
    return axios.post(API_URL + "onboarding-state", postData, { headers });
  }

  getGlobalShopData(shopId, userId) {
    // return axios.get(API_URL + shopId, { headers: authHeader() }).catch(error => { if (error.response.status === 401) { AuthService.logout(); } });
    const postData = qs.stringify({
      'userId': userId,
      'shopId': shopId
    })
    return axios.post(API_URL, postData, { headers });
  }

  updateProfile(userId, name, email, phone) {
    const postData = qs.stringify({
      'userId': userId,
      'name': name,
      'email': email,
      'phone': phone,
    })
    return axios.post(API_URL + "profile/update", postData, { headers });
  }

  changePassword(userId, oldPassword, newPassword) {
    const postData = qs.stringify({
      'userId': userId,
      'oldPassword': oldPassword,
      'newPassword': newPassword,
    })
    return axios.post(API_URL + "password/update", postData, { headers });
  }

  resetPassword(email) {
    const postData = qs.stringify({
      'email': email
    })
    return axios.post(API_URL + "password/reset", postData, { headers });
  }

  setNewPassword(email, password, token) {
    const postData = qs.stringify({
      'email': email,
      'password': password,
      'token': token,
    })
    return axios.post(API_URL + "password/new", postData, { headers });
  }

  linkShopifyStoreToTenant(shopId, tenantId) {
    const postData = qs.stringify({
      'shopId': shopId,
      'tenantId': tenantId
    })
    return axios.post(API_URL + "shop/link", postData, { headers });
  }

  switchShop(shopId) {
    const postData = qs.stringify({
      'shopId': shopId
    })
    return axios.post(API_URL + "shop/switch", postData, { headers });
  }
}

export default new UserService();

import axios from "axios";
import Cookies from 'js-cookie'

const API_URL = "https://backend.whatsmojo.com/api/auth/";
// const API_URL = "http://localhost:8080/api/auth/"

class AuthService {
  login(email, password, remember) {
    return axios
      .post(API_URL + "signin", {
        email,
        password,
        remember
      })
      .then(response => {
        if (response.data.accessToken) {
          response.data.sidebar = 'large'
          response.data.currentCohort = null
          response.data.currentFlow = null
          localStorage.setItem("user", JSON.stringify(response.data));
          if (response.data.shops.length > 0) {
            localStorage.setItem("currentShop", JSON.stringify(response.data.shops[0]));
          }
        }

        return response.data;
      });
  }

  logout() {
    // localStorage.removeItem("user");
    // localStorage.removeItem("currentShop");
    // remove mojoLoggedIn cookie
    Cookies.remove('mojoLoggedIn', { domain: '.whatsmojo.com', path: '/' });
    localStorage.clear();
    window.location.href = '/sign-in'
  }

  register(name, email, phone, companyName, industry, revenue, jobTitle, role, shopSystem, password, shopId) {
    return axios.post(API_URL + "signup", {
      'name': name,
      'email': email,
      'phone': phone,
      'companyName': companyName,
      'industry': industry,
      'revenue': revenue,
      'jobTitle': jobTitle,
      'role': role,
      'shopSystem': shopSystem,
      'password': password,
      'shopId': shopId
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  getCurrentShop() {
    return JSON.parse(localStorage.getItem('currentShop'));
  }
  setCurrentShop(shopData) {
    localStorage.setItem("currentShop", JSON.stringify(shopData))
  }
  setSidebar(sidebar) {
    var userData = JSON.parse(localStorage.getItem('user'))
    userData.sidebar = sidebar
    localStorage.setItem('user', JSON.stringify(userData))
  }
  setProfileSettings(name, email, phone) {
    var userData = JSON.parse(localStorage.getItem('user'))
    userData.username = name
    userData.email = email
    userData.phone = phone
    localStorage.setItem('user', JSON.stringify(userData))
  }
  setShopsForUser(shops) {
    var userData = JSON.parse(localStorage.getItem('user'))
    userData.shops = shops
    localStorage.setItem('user', JSON.stringify(userData))
  }
  setCurrentCohort(currentCohort) {
    var userData = JSON.parse(localStorage.getItem('user'))
    userData.currentCohort = currentCohort
    localStorage.setItem('user', JSON.stringify(userData))
  }
  setCurrentFlow(currentFlow) {
    var userData = JSON.parse(localStorage.getItem('user'))
    userData.currentFlow = currentFlow
    localStorage.setItem('user', JSON.stringify(userData))
  }
  setPermissions(missingPermission) {
    var userData = JSON.parse(localStorage.getItem('user'))
    userData.missingPermissions = missingPermission
    localStorage.setItem('user', JSON.stringify(userData))
  }
}

export default new AuthService();

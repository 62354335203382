import React, { Component, Suspense } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import logoWhite from './images/logo_white_plain.png'
import logoBlack from './images/logo_black.png'
import logoSmall from './images/logo_small.png'
import dasboardIcon from './images/dashboard-icon.png'
import chatsIcon from './images/chats-icon.png'
import campaignsIcon from './images/campaigns-icon.png'
import flowsIcon from './images/flows-icon.png'
import hubIcon from './images/hub-icon.png'
import listsIcon from './images/segments-icon.png'
import analyticsIcon from './images/analytics-icon.png'
import surveysIcon from './images/survey-icon.png'
import homeIcon from './images/home-icon.png'
import settingsIcon from './images/settings-icon.png'
import logoutIcon from './images/logout.png'
import arrowLeftIcon from './images/arrow-left.png'
import navbarMessages from './images/nav_messages.png'
import autoreplyIcon from './images/autoreplyIcon.png'
import widgetIcon from './images/widgets-icon.png'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container'
import { Spinner } from 'react-bootstrap'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import Footer from "./footer"

import AuthService from "./services/auth.service";
import UserService from "./services/user.service";
import BillingService from "./services/billing.service";

import { PrivateRoute } from './components/PrivateRoute.component';
import OnboardingGuard from './components/OnboardingGuard.component';

TimeAgo.addDefaultLocale(en)

const OnboardingPayment = React.lazy(() => import('./components/onboarding/payment.component'));
const OnboardingWhatsapp = React.lazy(() => import('./components/onboarding/whatsapp.component'));
const OnboardingShopify = React.lazy(() => import('./components/onboarding/shopify.component'));
const OnboardingSummary = React.lazy(() => import('./components/onboarding/summary.component'));

const Login = React.lazy(() => import('./components/login.component'));
const SignUp = React.lazy(() => import('./components/signup.component'));
const PasswordForget = React.lazy(() => import ('./components/passwordForget.component'));
const NewPassword = React.lazy(() => import ('./components/newPassword.component'));
const Imprint = React.lazy(() => import('./pages/imprint.page'));
const PrivacyPolicy = React.lazy(() => import('./pages/privacy-policy.page'));
const CookyPolicy = React.lazy(() => import('./pages/cookie-policy.page'))

const Home = React.lazy(() => import('./components/home.component'));
const Profile = React.lazy(() => import('./components/profile.component'));
const ProfileIntegrations = React.lazy(() => import('./components/profileIntegrations.component'));
const FacebookIntegrations = React.lazy(() => import('./components/facebookIntegrations.component'));
const WhatsappSettings = React.lazy(() => import('./components/whatsappSettings.component'));
const Billing = React.lazy(() => import('./components/billing.component'))
const Settings = React.lazy(() => import('./components/settings.component'))

const Dashboard = React.lazy(() => import('./components/dashboard.component'));
const Chat = React.lazy(() => import('./components/whatsapp.component'));
const Customers = React.lazy(() => import('./components/customers.component'));
const SurveyAnalyticsComponent = React.lazy(() => import('./components/surveyAnalytics.component'));

const Campaigns = React.lazy(() => import('./components/campaigns/campaigns.component'));
const CreateCampaign = React.lazy(() => import('./components/campaigns/createCampaign.component'));

const Segments = React.lazy(() => import('./components/segments/segments.component'));
const CreateSegment = React.lazy(() => import('./components/segments/createSegment.component'));

const NotificationHub = React.lazy(() => import('./components/hub/hub.component'));
const NotificationCreate = React.lazy(() => import('./components/hub/createNotification.component'));

const Autoreplies = React.lazy(() => import('./components/autoreplies/autoreplies.component'));
const AutoreplyCreate = React.lazy(() => import('./components/autoreplies/createAutoreply.component'));

const Widgets = React.lazy(() => import('./components/widgets/widgets.component'));
const WidgetCreate = React.lazy(() => import('./components/widgets/createWidget.component'));

const Flows = React.lazy(() => import('./components/flows/flows.component'));
const CreateFlow = React.lazy(() => import('./components/flows/createFlow.component'));

class App extends Component {

  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this)
    this.handleShopSelect = this.handleShopSelect.bind(this)
    this.toggleMobileNav = this.toggleMobileNav.bind(this)

    this.state = {
      currentUser: undefined,
      currentShop: null,
      currentPath: [],
      currentShopId: null,
      whatsappCount: 0,
      sidebarLarge: AuthService.getCurrentUser() !== null ? AuthService.getCurrentUser().sidebar === 'large' ? true : false : null,
      missingPermissions: false,
      showInfo: false,
      isScrolled: false,
      showMobileNav: false
    };
    // console.log(props);
  }

  componentDidMount() {

    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.setState({ isScrolled: true });
      } else {
        this.setState({ isScrolled: false });
      }

    });

    const user = AuthService.getCurrentUser();
    const currentShop = AuthService.getCurrentShop();

    if (user !== null && currentShop !== null) {
      // console.log(currentShop);
      UserService.getGlobalShopData(currentShop.id, user.id).then(
        response => {
          // console.log(response);
          this.setState({
            whatsappCount: response.data.whatsappData[0]
          })
          AuthService.setShopsForUser(response.data.allShops)
          // if (currentShop.whatsappNumber === null) {
            AuthService.setCurrentShop(response.data.shopData)
          // }

          if (!response.data.shopData.hasAllScopes) {
            // ask for reauthentication of shopify app if not all scopes are allowed
            this.setState({
              showInfo: true,
              missingPermissions: true
            })
            AuthService.setPermissions(true)
          } else {
            this.setState({
              showInfo: false,
              missingPermissions: false
            })
            AuthService.setPermissions(false)
          }
        }
      ).catch(error => { // (**)
        console.log(`The unknown error has occurred: ${error}`);
        // don't return anything => execution goes the normal way

      });
    }

    if (user) {
      this.setState({
        currentUser: user,
        currentShop: currentShop,
        currentPath: window.location.pathname.substring(1).split('/'),
        currentShopId: currentShop !== null ? currentShop.id : null
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  async handleManageBilling(e) {
    e.preventDefault();
    const currentShop = AuthService.getCurrentShop();
    try {
      const response = await BillingService.createCustomerPortalSession(currentShop.id);
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error('Error creating customer portal session:', error);
      // Handle error appropriately
    }
  }

  toggleSidebar() {
    AuthService.setSidebar(this.state.sidebarLarge ? 'small' : 'large')
    this.setState({
      sidebarLarge: !this.state.sidebarLarge
    })
  }

  toggleMobileNav() {
    this.setState({
      showMobileNav: !this.state.showMobileNav
    })
    document.documentElement.classList.toggle('noScroll')
  }

  handleShopSelect(event) {
    UserService.switchShop(event.target.value).then(
      response => {
        if (!response.data.shopData.hasAllScopes) {
          // ask for reauthentication of shopify app if not all scopes are allowed
          this.setState({
            showInfo: true,
            missingPermissions: true,
            currentShopId: response.data.shopData.id
          })
          AuthService.setPermissions(true)
        } else {
          this.setState({
            showInfo: false,
            missingPermissions: false,
            currentShopId: response.data.shopData.id
          })
          AuthService.setPermissions(false)
        }

        AuthService.setCurrentShop(response.data.shopData)
        AuthService.setCurrentCohort(null)
        AuthService.setCurrentFlow(null)
        const urlParams = new URLSearchParams(window.location.search)
        // console.log(urlParams.get('shopId'));
        if (urlParams.get('shopId') !== null) {
          let newURL = window.location.href.replace('shopId='+urlParams.get('shopId'), 'shopId='+response.data.shopData.id)
          window.location.href = newURL
        }
        // console.log(newURL);
      }
    )
  }

  render() {
    const { currentUser, currentPath, currentShop, currentShopId, whatsappCount, sidebarLarge, missingPermissions, isScrolled, showMobileNav } = this.state;
    const { loading } = this.props;
    const settingsTitle = (<><div className="profileImage"><span>{currentUser !== undefined ? currentUser.username.charAt(0) : 'U'}</span></div>{currentUser !== undefined ? currentUser.username : ''} <img src={arrowLeftIcon} className="dropdown-arrow" height="16" /></>);
    const publicPaths = [
      '/privacy-policy',
      '/imprint',
      '/book-a-demo',
      '/',
      '/landing',
      '/cookie-policy'
    ]
    const onboardingCompleted = JSON.parse(localStorage.getItem('onboardingCompleted'))

    if (publicPaths.includes(window.location.pathname)) {
      document.body.classList.add('outside-app')
    }

    // if (this.props.loading) {
    //   return (
    //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    //       <Spinner animation="border" />
    //     </div>
    //   );
    // }

    return (
      <Router>
      <div className="App">
        {currentUser ?
        <div className={`main logged-in ${publicPaths.includes(window.location.pathname) ? 'd-block pub-page' : ''} ${sidebarLarge ? 'sidebar-large' : 'sidebar-small'} ${!onboardingCompleted ? 'onboarding' : ''} ${(window.location.pathname.includes('/flows/create') || window.location.pathname.includes('/flows/edit')) ? 'flow-screen' : ''}`}>
          {!publicPaths.includes(window.location.pathname) ?
            <>
              <div className={`sidebar-toggle ${onboardingCompleted ? 'd-flex' : 'd-none'}`} onClick={this.toggleSidebar}>
                <img
                  alt=""
                  src={arrowLeftIcon}
                  height="9"
                />
              </div>
              {onboardingCompleted &&
                <Navbar className="sidebar">
                  <a href="/dashboard">
                    <img
                      alt=""
                      src={sidebarLarge ? logoWhite : logoSmall}
                      className="d-inline-block align-top mr-2 brand-logo pb-0"
                    />
                  </a>
                  {currentUser !== null &&
                    <>
                      <div className="scrollbar-container ps">
                        <ul className="sidebar-nav">
                          <li className="sidebar-item">
                            <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/dashboard') ? 'active' : null}`} href={currentShopId !== null ? `/dashboard?shopId=${currentShopId}` : '/dashboard'}>
                              <img src={dasboardIcon} width="18" height="18" className="nav-icon" alt="Dashboard Icon" />
                              <span className="align-middle" depth="0">Dashboard</span>
                            </a>
                          </li>
                          <li className="sidebar-item">
                            <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/chat') ? 'active' : null}`} href={currentShopId !== null ? `/chat?shopId=${currentShopId}` : '/chat'}>
                              <img src={chatsIcon} width="18" height="18" className="nav-icon" alt="Chat Icon" />
                              <span className="align-middle" depth="0">Chats ({(whatsappCount.waOpenCount !== null && whatsappCount.waOpenCount !== undefined) ? whatsappCount.waOpenCount : 0})</span>
                            </a>
                          </li>
                          {currentUser.id === 13 &&
                          <li className="sidebar-item">
                            <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/customers') ? 'active' : null}`} href={currentShopId !== null ? `/customers?shopId=${currentShopId}` : '/customers'}>
                              <img src={listsIcon} width="18" height="18" className="nav-icon" alt="Customers Icon" />
                              <span className="align-middle" depth="0">Customers</span>
                            </a>
                          </li>
                          }
                          { (currentShop.provider === "360") &&
                            <>
                              <li className="sidebar-item">
                                <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/segments') ? 'active' : null}`} href={currentShopId !== null ? `/segments?shopId=${currentShopId}` : '/segments'}>
                                  <img src={listsIcon} width="18" height="18" className="nav-icon" alt="Segments Icon" />
                                  <span className="align-middle" depth="0">Lists & Segments</span>
                                </a>
                              </li>
                              <li className="sidebar-item">
                                <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/campaigns') ? 'active' : null}`} href={currentShopId !== null ? `/campaigns?shopId=${currentShopId}` : '/campaigns'}>
                                  <img src={campaignsIcon} width="18" height="18" className="nav-icon" alt="Campaigns Icon" />
                                  <span className="align-middle" depth="0">Campaigns</span>
                                </a>
                              </li>
                              <li className="sidebar-item">
                                <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/flows') ? 'active' : null}`} href={currentShopId !== null ? `/flows?shopId=${currentShopId}` : '/flows'}>
                                  <img src={flowsIcon} width="18" height="18" className="nav-icon" alt="Flows Icon" />
                                  <span className="align-middle" depth="0">Flows</span>
                                </a>
                              </li>
                              <li className="sidebar-item">
                                <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/hub') ? 'active' : null}`} href={currentShopId !== null ? `/hub?shopId=${currentShopId}` : '/hub'}>
                                  <img src={hubIcon} width="18" height="18" className="nav-icon" alt="Hub Icon" />
                                  <span className="align-middle" depth="0">Notification Hub</span>
                                </a>
                              </li>
                              <li className="sidebar-item">
                                <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/autoreplies') ? 'active' : null}`} href={currentShopId !== null ? `/autoreplies?shopId=${currentShopId}` : '/autoreplies'}>
                                  <img src={autoreplyIcon} width="18" height="18" className="nav-icon" alt="Autoreplies Icon" />
                                  <span className="align-middle" depth="0">Autoreplies</span>
                                </a>
                              </li>
                              <li className="sidebar-item">
                                <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/widgets') ? 'active' : null}`} href={currentShopId !== null ? `/widgets?shopId=${currentShopId}` : '/widgets'}>
                                  <img src={widgetIcon} width="18" height="18" className="nav-icon" alt="Widgets Icon" />
                                  <span className="align-middle" depth="0">Widgets</span>
                                </a>
                              </li>
                            </>
                          }
                          {/*<li className="sidebar-item">
                            <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/analytics/survey') ? 'active' : null}`} href={currentShopId !== null ? `/analytics/survey?shopId=${currentShopId}` : '/analytics/survey'}>
                              <img src={analyticsIcon} width="18" height="18" className="nav-icon" alt="Campaign Icon" />
                              <span className="align-middle" depth="0">Survey Analytics</span>
                            </a>
                          </li>
                          <li className="sidebar-item">
                            <a depth="0" className={`sidebar-link ${window.location.pathname.includes('/flows') ? 'active' : null}`} href={currentShopId !== null ? `/flows?shopId=${currentShopId}` : '/flows'}>
                              <img src={flowsIcon} width="18" height="18" className="nav-icon" alt="Flow Icon" />
                              <span className="align-middle" depth="0">Flows</span>
                            </a>
                          </li>*/}
                        {/*<ul className="sidebar-nav">
                          <li className="sidebar-item">
                            <a depth="0" style={{ pointerEvents: 'none' }} className={`sidebar-link inactive ${window.location.pathname.includes('/lists') ? 'active' : null}`} href="/#">
                              <img src={listsIcon} width="18" height="18" className="nav-icon" alt="List & Segment Icon" />
                              <span className="align-middle" depth="0">Lists & Segments <span className="coming-soon">coming soon...</span></span>
                            </a>
                          </li>
                          <li className="sidebar-item">
                            <a depth="0" style={{ pointerEvents: 'none' }} className={`sidebar-link inactive ${window.location.pathname.includes('/analytics') ? 'active' : null}`} href="/#">
                              <img src={analyticsIcon} width="18" height="18" className="nav-icon" alt="Analytics Icon" />
                              <span className="align-middle" depth="0">Analytics <span className="coming-soon">coming soon...</span></span>
                            </a>
                          </li>
                          <li className="sidebar-item">
                            <a depth="0" style={{ pointerEvents: 'none' }} className={`sidebar-link inactive ${window.location.pathname.includes('/surveys') ? 'active' : null}`} href="/#">
                              <img src={surveysIcon} width="18" height="18" className="nav-icon" alt="Survey Icon" />
                              <span className="align-middle" depth="0">Surveys <span className="coming-soon">coming soon...</span></span>
                            </a>
                          </li>*/}
                        </ul>
                      </div>
                      <ul className="logout-ul">
                        {/*{currentShop !== null && !currentShop.hasActiveSubscription &&
                        <li className="sidebar-item">
                          <div className="upgrade-badge">
                            <div className="upgrade-inner">
                              <div className="upgrade-title">Go Pro</div>
                              <div className="free-tracker">
                                <div className="free-progress">12 / 250</div>
                                <div className="free-label">Free notifications</div>
                              </div>
                              <div className="upgrade-cta">
                                <a href="#" className="upgrade-btn">Upgrade now</a>
                              </div>
                            </div>
                          </div>
                        </li>
                        }*/}
                        <li className="sidebar-item">
                          <a className="sidebar-link logout-btn" href="/sign-in" onClick={this.logOut}>
                            <img src={logoutIcon} width="18" height="18" className="nav-icon" alt="Logout Icon" />
                            <span className="align-middle" depth="0">Log out</span>
                          </a>
                        </li>
                      </ul>
                    </>
                  }
                </Navbar>
              }
              <div id="wrapper" className={`w-100 ${!onboardingCompleted ? 'full-width' : null}`}>
                <Navbar collapseOnSelect expand="lg" className={`px-3 bg-white navtop align-items-center ${!onboardingCompleted ? 'full-width' : null}`} fixed="top">
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                      {!onboardingCompleted &&
                        <img src={logoBlack} height="35" />
                      }
                    </Nav>
                    <Nav>
                      <div className="navbar-nav ml-auto align-items-center">
                        {(currentShopId !== null && currentUser.shops.length > 1) &&
                        <li className="nav-item">
                          <Form.Select aria-label="Select Shop" onChange={this.handleShopSelect} defaultValue={currentShopId}>
                            {Object.keys(currentUser.shops).map((keyName, i) => (
                              <option key={i} data-key={keyName} data-name={currentUser.shops[keyName].shopName} value={currentUser.shops[keyName].id}>{currentUser.shops[keyName].shopName} </option>
                            ))}
                          </Form.Select>
                        </li>
                        }
                        {onboardingCompleted &&
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="messages-tooltip">
                              <span>You have {(whatsappCount.waUnreadCount !== null && whatsappCount.waUnreadCount !== undefined) ? whatsappCount.waUnreadCount : 0} unread messages</span>
                            </Tooltip>}
                          >
                          <li className="nav-item chat-notification">
                              <a href={currentShopId !== null ? `/chat?shopId=${currentShopId}` : 'chat'}>
                                {(whatsappCount.waUnreadCount !== null && whatsappCount.waUnreadCount !== undefined && whatsappCount.waUnreadCount > 0) &&
                                  <span className="new-messages"></span>
                                }
                                <img src={navbarMessages} />
                              </a>
                          </li>
                          </OverlayTrigger>
                        }
                        <li className="nav-item">
                          <NavDropdown title={settingsTitle} id="profile-dropdown" align="end">
                            {onboardingCompleted &&
                              <>
                                <Nav.Link href="/settings/profile">Profile Settings</Nav.Link>
                                {currentUser.type === 0 &&
                                  <>
                                    <Nav.Link href="/settings/integrations">Integrations</Nav.Link>
                                    {currentShop.provider === "360" && (
                                      <Nav.Link href="/settings/whatsapp">WhatsApp Settings</Nav.Link>
                                    )}
                                    <Nav.Link href="/settings/billing">Billing</Nav.Link>
                                    {currentUser.email === 'fabian@12kmedia.com' &&
                                      <>
                                        <Nav.Link href="/settings/facebook">Facebook Integration</Nav.Link>
                                      </>
                                    }
                                  </>
                                }
                                <NavDropdown.Divider />
                              </>
                            }
                            {!onboardingCompleted && !currentPath.includes('payment') && (
                              <button
                                type="button"
                                className="dropdown-item billing-btn"
                                onClick={this.handleManageBilling}
                              >
                                Manage billing
                              </button>
                            )}
                            <Nav.Link href="/sign-in" onClick={this.logOut}>Log Out</Nav.Link>
                          </NavDropdown>
                        </li>
                      </div>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <div className={`container-fluid wrapper-content ${window.location.pathname.includes('/chat') ? 'px-0' : ''}`}>
                  {missingPermissions &&
                    <Alert key="danger" variant="danger">
                      We are missing some Shopify permissions in order to use all features. Please reauthenticate with Shopify to grant access.{' '} <Alert.Link href='/settings/integrations'>Click here</Alert.Link> to update your integration.
                    </Alert>
                  }
                  <Suspense fallback={null}>
                  <Routes>
                    <Route path='*' element={<Navigate replace to="/" />} />
                    <Route path="/" element={<Navigate replace to={currentUser !== undefined ? "/dashboard?shopId="+currentShopId : "/dashboard"} />} />
                    <Route path="/sign-in" element={<Login />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route path="/password-forget" element={<PasswordForget />} />
                    <Route path="/change-password" element={<NewPassword />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/settings/profile" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Profile /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/settings/integrations" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><ProfileIntegrations missingPermissions={missingPermissions} /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/settings/whatsapp" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><WhatsappSettings /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/settings/facebook" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><FacebookIntegrations /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/settings/billing" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Billing /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/dashboard" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Dashboard /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/chat" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Chat /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/customers" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Customers /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/analytics/survey" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><SurveyAnalyticsComponent /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/campaigns" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Campaigns /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/campaigns/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateCampaign /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/segments" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Segments /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/segments/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateSegment /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/hub" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><NotificationHub /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/hub/template/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><NotificationCreate /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/autoreplies" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Autoreplies /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/autoreplies/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><AutoreplyCreate /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/widgets" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Widgets /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/widgets/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><WidgetCreate /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/flows" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Flows /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/flows/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateFlow /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/flows/edit/:id" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateFlow /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/imprint" element={<Imprint />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/cookie-policy" element={<CookyPolicy />} />
                    <Route path="/onboarding/payment" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingPayment /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/onboarding/whatsapp" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingWhatsapp /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/onboarding/shopify" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingShopify /></PrivateRoute></OnboardingGuard>} />
                    <Route path="/onboarding/summary" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingSummary /></PrivateRoute></OnboardingGuard>} />
                  </Routes>
                  </Suspense>
                </div>
              </div>
            </>
          :
          <>
            <div className="navbar-main">
              <div className="navbar-content">
                <div className={`navbar-grid ${isScrolled ? 'nav-scrolled' : ''}`}>
                  <a className="logo" href="/"><img src={logoBlack} /></a>
                  <div className="nav-right">
                    <a href={currentShopId !== null ? `/dashboard?shopId=${currentShopId}` : '/dashboard'} className="demo-btn">Go to app</a>
                    {/*<a href="/sign-in" className="login-btn">Sign in</a>
                    <a href="/book-demo" className="demo-btn">Try for free</a>
                    */}
                    <button tabIndex="0" className={`mobile-nav ${showMobileNav ? 'mobile-nav-open' : ''}`} onClick={this.toggleMobileNav}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                  <div className={`nav-middle ${showMobileNav ? 'nav-middle-mobile' : ''}`}>
                    <ul className="nav-items-middle">
                      <li role="menuitem" className="nav-item">
                        <a tabIndex="0" className="menu-item" href="/">Product</a>
                      </li>
                      <li role="menuitem" className="nav-item">
                        <a tabIndex="0" className="menu-item" href="/">Features</a>
                      </li>
                      <li role="menuitem" className="nav-item">
                        <a tabIndex="0" className="menu-item" href="/">Case Study</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className={`container-fluid wrapper-content`}>
              <Suspense fallback={null}>
              <Routes>
                <Route path='*' element={<Navigate replace to="/" />} />
                <Route path="/" element={<Navigate replace to={currentUser !== undefined ? "/dashboard?shopId="+currentShopId : "/dashboard"} />} />
                <Route path="/sign-in" element={<Login />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/password-forget" element={<PasswordForget />} />
                <Route path="/change-password" element={<NewPassword />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/settings/profile" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Profile /></PrivateRoute></OnboardingGuard>} />
                <Route path="/settings/integrations" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><ProfileIntegrations missingPermissions={missingPermissions} /></PrivateRoute></OnboardingGuard>} />
                <Route path="/settings/whatsapp" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><WhatsappSettings /></PrivateRoute></OnboardingGuard>} />
                <Route path="/settings/facebook" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><FacebookIntegrations /></PrivateRoute></OnboardingGuard>} />
                <Route path="/settings/billing" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Billing /></PrivateRoute></OnboardingGuard>} />
                <Route path="/dashboard" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Dashboard /></PrivateRoute></OnboardingGuard>} />
                <Route path="/chat" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Chat /></PrivateRoute></OnboardingGuard>} />
                <Route path="/customers" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Customers /></PrivateRoute></OnboardingGuard>} />
                <Route path="/analytics/survey" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><SurveyAnalyticsComponent /></PrivateRoute></OnboardingGuard>} />
                <Route path="/campaigns" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Campaigns /></PrivateRoute></OnboardingGuard>} />
                <Route path="/campaigns/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateCampaign /></PrivateRoute></OnboardingGuard>} />
                <Route path="/segments" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Segments /></PrivateRoute></OnboardingGuard>} />
                <Route path="/segments/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateSegment /></PrivateRoute></OnboardingGuard>} />
                <Route path="/hub" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><NotificationHub /></PrivateRoute></OnboardingGuard>} />
                <Route path="/hub/template/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><NotificationCreate /></PrivateRoute></OnboardingGuard>} />
                <Route path="/autoreplies" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Autoreplies /></PrivateRoute></OnboardingGuard>} />
                <Route path="/autoreplies/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><AutoreplyCreate /></PrivateRoute></OnboardingGuard>} />
                <Route path="/widgets" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Widgets /></PrivateRoute></OnboardingGuard>} />
                <Route path="/widgets/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><WidgetCreate /></PrivateRoute></OnboardingGuard>} />
                <Route path="/flows" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Flows /></PrivateRoute></OnboardingGuard>} />
                <Route path="/flows/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateFlow /></PrivateRoute></OnboardingGuard>} />
                <Route path="/flows/edit/:id" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateFlow /></PrivateRoute></OnboardingGuard>} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cookie-policy" element={<CookyPolicy />} />
                <Route path="/onboarding/payment" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingPayment /></PrivateRoute></OnboardingGuard>} />
                <Route path="/onboarding/whatsapp" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingWhatsapp /></PrivateRoute></OnboardingGuard>} />
                <Route path="/onboarding/shopify" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingShopify /></PrivateRoute></OnboardingGuard>} />
                <Route path="/onboarding/summary" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingSummary /></PrivateRoute></OnboardingGuard>} />
              </Routes>
              </Suspense>
            </div>
          </>
          }
        </div>
        :
        <>
        <div className={`main logged-out pub-page`}>
          <div className="loggedOut-content">
            <Suspense fallback={null}>
            <Routes>
              <Route path='*' element={<Navigate replace to="/sign-in" />} />
              <Route path="/sign-in" element={<Login />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/password-forget" element={<PasswordForget />} />
              <Route path="/change-password" element={<NewPassword />} />
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/cookie-policy" element={<CookyPolicy />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/profile" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Profile /></PrivateRoute></OnboardingGuard>} />
              <Route path="/settings/integrations" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><ProfileIntegrations missingPermissions={missingPermissions} /></PrivateRoute></OnboardingGuard>} />
              <Route path="/settings/whatsapp" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><WhatsappSettings /></PrivateRoute></OnboardingGuard>} />
              <Route path="/settings/facebook" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><FacebookIntegrations /></PrivateRoute></OnboardingGuard>} />
              <Route path="/settings/billing" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Billing /></PrivateRoute></OnboardingGuard>} />
              <Route path="/dashboard" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Dashboard /></PrivateRoute></OnboardingGuard>} />
              <Route path="/chat" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Chat /></PrivateRoute></OnboardingGuard>} />
              <Route path="/customers" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Customers /></PrivateRoute></OnboardingGuard>} />
              <Route path="/analytics/survey" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><SurveyAnalyticsComponent /></PrivateRoute></OnboardingGuard>} />
              <Route path="/campaigns" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Campaigns /></PrivateRoute></OnboardingGuard>} />
              <Route path="/campaigns/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateCampaign /></PrivateRoute></OnboardingGuard>} />
              <Route path="/segments" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Segments /></PrivateRoute></OnboardingGuard>} />
              <Route path="/segments/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateSegment /></PrivateRoute></OnboardingGuard>} />
              <Route path="/hub" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><NotificationHub /></PrivateRoute></OnboardingGuard>} />
              <Route path="/hub/template/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><NotificationCreate /></PrivateRoute></OnboardingGuard>} />
              <Route path="/autoreplies" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Autoreplies /></PrivateRoute></OnboardingGuard>} />
              <Route path="/autoreplies/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><AutoreplyCreate /></PrivateRoute></OnboardingGuard>} />
              <Route path="/widgets" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Widgets /></PrivateRoute></OnboardingGuard>} />
              <Route path="/widgets/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><WidgetCreate /></PrivateRoute></OnboardingGuard>} />
              <Route path="/flows" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><Flows /></PrivateRoute></OnboardingGuard>} />
              <Route path="/flows/create" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateFlow /></PrivateRoute></OnboardingGuard>} />
              <Route path="/flows/edit/:id" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><CreateFlow /></PrivateRoute></OnboardingGuard>} />
              <Route path="/onboarding/payment" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingPayment /></PrivateRoute></OnboardingGuard>} />
              <Route path="/onboarding/whatsapp" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingWhatsapp /></PrivateRoute></OnboardingGuard>} />
              <Route path="/onboarding/shopify" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingShopify /></PrivateRoute></OnboardingGuard>} />
              <Route path="/onboarding/summary" element={<OnboardingGuard shopId={currentShopId}><PrivateRoute><OnboardingSummary /></PrivateRoute></OnboardingGuard>} />
            </Routes>
            </Suspense>
          </div>
        </div>
        </>
      }
      </div>

      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.onboarding.loading,
  };
};

export default connect(mapStateToProps)(App);

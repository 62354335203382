import axios from "axios";
import authHeader from './auth-header';
import AuthService from "./auth.service";

const API_URL = "https://backend.whatsmojo.com/api/billing/";
// const API_URL = "http://localhost:8080/api/billing/";

const qs = require('qs');

const headers = {
  'apikey': process.env.REACT_APP_APIKEY,
  'Content-Type': 'application/x-www-form-urlencoded'
}

class BillingService {
  getBilling(shopId, startDate, endDate) {
    // return axios.get(API_URL + shopId, { headers: authHeader() }).catch(error => { if (error.response.status === 401) { AuthService.logout(); } });
    const postData = qs.stringify({
      'shopId': shopId,
      'startDate': startDate,
      'endDate': endDate
    })
    return axios.post(API_URL, postData, { headers });
  }

  getInvoices(shopId) {
    const postData = qs.stringify({
      'shopId': shopId
    })
    return axios.post(API_URL + 'invoices', postData, { headers });
  }

  createCustomerPortalSession(shopId) {
    const postData = qs.stringify({ 'shopId': shopId });
    return axios.post(API_URL + 'stripe/portal', postData, { headers });
  }

  createSetupIntent(shopId) {
    const postData = qs.stringify({
      'shopId': shopId
    })
    return axios.post(API_URL+'payment/setup-intent', postData, { headers });
  }

  storePaymentMethod(shopId, stripePaymentMethodId, startSubscription, accountReactivation, currency = null) {
    const postData = qs.stringify({
      'shopId': shopId,
      'stripePaymentMethodId': stripePaymentMethodId,
      'startSubscription': startSubscription,
      'accountReactivation': accountReactivation,
      'currency': currency
    });
    return axios.post(API_URL + 'payment/store', postData, { headers });
  }

  storeBusinessInfo(shopId, country, companyName, street1, street2, city, state, zip, taxId, addressId) {
    const postData = qs.stringify({
      'shopId': shopId,
      'country': country,
      'companyName': companyName,
      'street1': street1,
      'street2': street2,
      'city': city,
      'state': state,
      'zipCode': zip,
      'vatNumber': taxId,
      'addressId': addressId
    })
    return axios.post(API_URL+'address/store', postData, { headers });
  }

  deletePaymentMethod(shopId, stripePaymentMethodId, paymentMethodId) {
    const postData = qs.stringify({
      'shopId': shopId,
      'stripePaymentMethodId': stripePaymentMethodId,
      'paymentMethodId': paymentMethodId
    })
    return axios.post(API_URL+'payment/delete', postData, { headers })
  }

  setDefaultPaymentMethod(shopId, subscriptionId, paymentMethodId, stripePaymentMethodId) {
    const postData = qs.stringify({
      'shopId': shopId,
      'subscriptionId': subscriptionId,
      'paymentMethodId': paymentMethodId,
      'stripePaymentMethodId': stripePaymentMethodId
    })
    return axios.post(API_URL+'payment/default', postData, { headers })
  }

  updateSubscription(shopId, subscriptionId, stripeSubscriptionId, isCanceled, startSubscription) {
    const postData = qs.stringify({
      'shopId': shopId,
      'subscriptionId': subscriptionId,
      'stripeSubscriptionId': stripeSubscriptionId,
      'isCanceled': isCanceled,
      'startSubscription': startSubscription
    })
    console.log(postData);
    return axios.post(API_URL+'subscription/update', postData, { headers })
  }
}

export default new BillingService();

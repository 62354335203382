import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import AuthService from "../services/auth.service";

// export const PrivateRoute = ({ component: Component, ...rest }) => (
//     <Outlet {...rest} render={props => {
//         const currentUser = AuthService.getCurrentUser();
//         // console.log(currentUser);
//         if (!currentUser) {
//             // not logged in so redirect to login page with the return url
//             return <Navigate to={{ pathname: '/sign-in', state: { from: props.location } }} />
//         }
//
//         // authorised so return component
//         return <Component {...props} />
//     }} />
// )

export const PrivateRoute = ({ children }) => {
  const currentUser = AuthService.getCurrentUser();
  return currentUser ? children : <Navigate to="/sign-in" />;
}

const initialState = {
  loading: true,
  onboardingStep: null,
  onboardingCompleted: false,
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'LOADED':
      return {
        ...state,
        loading: false,
      };
    case 'FETCH_ONBOARDING_STATE':
      return {
        ...state,
        onboardingStep: action.payload.step,
        onboardingCompleted: action.payload.completed,
      };
    case 'UPDATE_ONBOARDING_STEP':
      return {
        ...state,
        onboardingStep: action.payload.step,
        onboardingCompleted: action.payload.completed,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default onboardingReducer;

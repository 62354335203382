import UserService from './services/user.service';

export const fetchOnboardingState = (shopId) => async (dispatch) => {
  dispatch({ type: 'LOADING' });
  const response = await UserService.getOnboardingState(shopId);
  localStorage.setItem('onboardingCompleted', JSON.stringify(response.data.completed));
  dispatch({ type: 'FETCH_ONBOARDING_STATE', payload: response.data });
  dispatch({ type: 'LOADED' });
};

export const updateOnboardingStep = (step, completed) => ({
  type: 'UPDATE_ONBOARDING_STEP',
  payload: { step, completed },
});

export const setLoading = (loading) => ({
  type: 'SET_LOADING',
  payload: loading,
});

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOnboardingState, setLoading } from '../actions';

function OnboardingGuard({ children, shopId }) {
  const { loading, onboardingStep, onboardingCompleted } = useSelector(state => state.onboarding) || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onboardingPaths = {
    1: "/onboarding/payment",
    2: "/onboarding/whatsapp",
    3: "/onboarding/shopify",
    4: "/onboarding/summary"
  };

  const handleNavigation = () => {
    if (!loading && onboardingStep !== null) {
      if (!onboardingCompleted && location.pathname !== onboardingPaths[onboardingStep]) {
        navigate(onboardingPaths[onboardingStep]);
      } else if (onboardingCompleted && Object.values(onboardingPaths).includes(location.pathname)) {
        navigate(`/dashboard?shopId=${shopId}`);
      } else {
        const currentPathStep = Object.keys(onboardingPaths).find(key => onboardingPaths[key] === location.pathname);
        if (currentPathStep && Number(currentPathStep) < onboardingStep) {
          navigate(onboardingPaths[onboardingStep]);
        }
      }
    }
  }

  useEffect(() => {
    if (shopId) {
      const onboardingCompleted = JSON.parse(localStorage.getItem('onboardingCompleted'));
      if (!onboardingCompleted) {
        dispatch(fetchOnboardingState(shopId));
      } else {
        dispatch(setLoading(false)); // Dispatch setLoading with false when onboardingCompleted is true in localStorage
      }
    }
  }, [dispatch, shopId]);

  useEffect(() => {
    handleNavigation();
  }, [loading, onboardingStep, onboardingCompleted]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return children;
}

export default OnboardingGuard;
